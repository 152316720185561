/* eslint-disable @typescript-eslint/no-unused-vars */
const translations = {
  /* eslint-disable max-len */
  hola: 'hola-es',
  ca: 'ca',
  es: 'es',
  open: 'Mañana',
  close: 'Tarde',
  solicitante: 'Solicitante',
  'Clients List': 'Lista de Clientes',
  Client: 'Cliente',
  User: 'Usuario',
  Tipus: 'Tipos',
  noData: 'Sin datos',
  NoPermisoUsuarioPromotor: 'No tienes permisos para ver este componente',
  Me: 'Perfil',
  'Production guarantee (kWh/year)': 'Garantía de producción (kWh/anual)',
  'Beta distribution (KWh)': 'Beta distribución (KWh)',
  'Beta distribution (%)': 'Beta distribución (%)',
  "Holder's Name": 'Nombre del Titular',
  'CONFIGURACION NO TIENE MIEMBROS': 'Configuración no tiene miembro',
  'Lanzar informe': 'Lanzar informe',
  Town: 'Población',
  Province: 'Provincia',
  'Contact name': 'Persona de contacto',
  Telephone: 'Teléfono',
  'Client saved!': 'Cliente guardado',
  'Data client': 'Datos cliente',
  'Report client': 'Informes clientes',
  'Web Page': 'Página Web',
  'Web page address': 'Dirección página web',
  Email: 'Email',
  'Email address': 'Dirección de email',
  'Enter a description': 'Introduce una descripción',
  'Elecsum green configuration': 'Configuración Elecsum Green',
  'Elecsum green selecciona tamaño': 'Selecciona el tamaño:',

  Headline: 'Titular',
  Categoria: 'Categoría ∗',
  CategoriaHead: 'Categoría',
  PreguntaHead: 'Pregunta',
  RespuestaHead: 'Respuesta',
  IdiomaHead: 'Idioma',
  Valor: 'Valor',
  Text: 'Texto ∗',
  TextHead: 'Texto',
  HorasHead: 'Horas',
  horaInicio: 'Hora inicio',
  horaFin: 'Hora fin',
  FranjaHead: 'Franja',
  'News deleted successfully': 'Las noticias han sido eliminadas correctamente',
  'caracteristicas  deleted successfully': 'Less característica han sido eliminadas correctamente',
  'Error deleting caracteristicas': 'Error al eliminar la característica',
  'Insert caracteristicas': 'Añade característica',
  'Headline new': 'Titular de la noticia',
  Date: 'Fecha',
  Description: 'Descripción',
  'News date (yyyy-mm-dd)': 'Fecha de la noticia (año-mes-día)',
  'News description': 'Descripción de la noticia',
  'News description (max 1000 characters)': 'Descripción de la noticia (máx. 1.000 caracteres)',
  'Environment news': 'Noticias Medio ambiente',
  caracateristicas: 'Características',
  'Insert comunidad': 'Añadir',

  'Insert new': 'Añadir noticia',
  Name: 'Nombre',
  'Installation name': 'Nombre de la instalación',
  'Number of panels': 'Número de paneles',
  Power: 'Potencia',
  'Number of inversors': 'Número de inversores',
  'Quantity of inversors': 'Cantidad de inversores',
  'Inversors Power': 'Potencia Inversor',
  'Inversors Power [kWh]': 'Potencia Inversor [kWh]',
  'Total Power': 'Potencia Total',
  'Total Power [kWh]': 'Potencia Total [kWh]',
  'Orientation (º)': 'Orientación (º)',
  'Tilt (º)': 'Inclinación (º)',
  'Energy ratio': 'Ratio energético [kWh/kWp]',
  Warranty: 'Garantía',
  'Has warranty? Yes/No': '¿Tiene Garantía? Sí/No',
  'If so, how many years?': 'En caso afirmativo, ¿cuántos años?',
  'Service agreement?': 'Contrato de mantenimiento',
  'Service agreement? Yes/No': '¿Tiene mantenimiento? Si/No',
  'Installation type (legalization)': 'Tipo de instalación (legalización)',
  'Date of reception of work': 'Fecha recepción de obra',
  'yyyy-mm-dd (year-month-day)': 'aaaa-mm-dd (año-mes-día)',
  'Start date production monitoring': 'Fecha inicio de seguimiento de producción',
  'Serial number consumption meter': 'Número serié contador consumo',
  'Serial number meter': 'núm. Serie Contador',
  Manufacturer: 'Fabricante',
  "Panel manufacturer's name": 'Nombre del fabricante del Panel',
  'Panel model name': 'Nombre del modelo del panel',
  Model: 'Modelo',
  Globales: 'Detalles de la combinación óptima',
  'Inverter (Wn)': 'Inversor (Wn)',
  'Inverter power': 'Poténcia del inversor',
  'Degradation year 0 (%)': 'Degradación año 0 (%)',
  'Degradation year 1 (%)': 'Degradación año 1 (%)',
  'Degradation year 2 (%)': 'Degradación año 2 (%)',
  'Degradation year 10 (%)': 'Degradación año 10 (%)',
  'Degradation year 25 (%)': 'Degradación año 25 (%)',
  '(%) degradation': '(%) degradación',
  "Inverter manufacturer's name": 'Nombre del fabricante del inversor',
  "Inverter model's name": 'Nombre del modelo del inversor',
  latitude: 'latitud',
  longitude: 'longitud',
  Latitude: 'Latitud',
  Longitude: 'Longitud',
  'Radio for comparative monitoring with other facilities (km)':
    'Radio para realizar el seguimiento comparativo con otras instalaciones (km)',
  'Maximum allowable deviation between facilities (%)': 'Desviación máxima permitida entre instalaciones (%)',
  'Enter a note or reminder': 'Introduzca una nota o recordatorio',
  'Installation Data': 'Datos Instalación',
  'Comunidad Data': 'Asociar comunidad',
  'Panel Model': 'Modelo Panel',
  'Inverter Model': 'Modelo Inversor',
  'Location facilitie': 'Ubicación Instalación',
  'Facilitie radius': 'Radio de instalaciones',
  'Error updating report!': 'Error en  actualizar',
  'Inscrito successfully updated': 'El inscrito  ha sido  actualizado correctamente',
  Deviation: 'Desviación',
  Notes: 'Notas',
  Cancel: 'Cancelar',
  Aceptar: 'Aceptar',
  Save: 'Guardar',
  Validar: 'Validar',
  Rebutjar: 'Rechazar',
  Clients: 'Clientes',
  Installation: 'Instalación',
  installation: 'instalación',
  Study: 'Estudio',
  Production: 'Producción',
  getSummaryDataError: 'Error al cargar los datos del resumen de contrato',
  getSharedEnergyDataError: 'Error al cargar los datos de generación repartida',
  'Comunidad successfully updated': 'La comunidad ha sido  actualizada correctamente',
  'Installation saved!': '¡Instalación guardada!',
  'Error Updating Installation.': 'Error al actualizar instalación.',
  'Error saving new!': 'Error al guardar',
  'NEW saved!': '¡Noticia Guardada!',
  'NEW user!': '¡Usuario Guardado!',

  'caracteristica saved!': 'Característica Guardada!',

  'Oversized image!': '¡Imagen demasiado grande!',
  'Not authorised!': '¡No autorizado!',
  'Unprivileged!': '¡No tienes privilegios de acceso!',
  'NIF not found in the system': 'NIF no encontrado en el sistema',
  'Fotovoltaic FV': 'Fotovoltaic FV',
  'Complete + deviation': 'Completo + Desviación',
  'Production guarantee': 'Garantía de producción',
  'Cliente actualizado correctamente': 'Cliente actualizado correctamente',
  'Promotor actualizado correctamente': 'Promotor actualizado correctamente',
  'El email de prueba ha sido enviado con éxito': '	El email de prueba ha sido enviado con éxito',

  'Images section about the company': 'Imágenes sección sobre la empresa',
  'add image': 'añade imagen',
  'add logo': 'añade logo',

  'add docu': 'añade documento',
  'add curva': 'añade curva',
  'add galery image': 'añade imagen de galería',
  'add galery logo': 'añade un logo de la  galería',
  'add all': 'Añade los documentos',
  'Peso Documento': '(5mb max PDF o JPG)',
  '(Must be 950x527px *)': '(Debe ser de 950x527px *)',
  '(Must be 160x200px *)': '(Debe ser de 160x200px *)',

  'We recommend 4 3 ratio and max 4MB': 'Recomendamos ratio 4:3 y máx. 4MB',
  'Header background image': 'Imagen de fondo cabecera',
  'add company background image': 'añade imagen de fondo de la empresa',
  'Must be 1440x120px *': 'Debe ser de 1440x120px *',
  'Company Logo': 'Logo empresa',
  'add company logo': 'añade logo de la empresa',
  '130x74 px *': '130x74 px *',
  'Successfully added image': 'Imagen añadida correctamente',
  'Too big image': 'Imagen demasiado grande',
  'Not authorized': 'No autorizado',
  'Inscrito validado': 'Validado Correctamente',
  YaValidado: 'Inscrito ya validado.',
  AlreadyValidated: 'Inscrito ya validado.',
  Unauthorized: 'Inscrito sin autorización Datadis.',
  ValidationError: 'Error validando al inscrito.',
  ErrorInscritoValidado: 'Error al validar el inscrito.',
  mensajeModal: 'Estás seguro que quiere validar a ',
  mensajeModalTarifa: 'Estás seguro que quiere validar la solicitud de ',
  'Without access privileges': 'No tiene privilegios de acceso',
  'Nif not found on system': 'Nif no encontrado en el sistema',
  'Inscrit not found on system': 'Inscrito no encontrado en el sistema',
  'Error deleting image': 'Se ha producido un error al eliminar la imagen',
  'Error deleting inscrito': 'Se ha producido un error al eliminar al inscrito',

  'Error deleting logo': 'Se ha producido un error al eliminar el logo',

  'Error: Some empty field': 'Error: Hay campos vacíos',
  'Image saved!': 'Imagen guardado',
  'CSV saved!': 'Csv guardado',
  'Logo saved!': 'Logo guardado',
  'Inscrito saved!': 'Documento guardado',
  'Image deleted successfully': 'Imagen eliminado',
  'Logo deleted successfully': 'Logo eliminado',
  'Inscrito deleted successfully': 'Documento Eliminado',
  'Deleted successfully inscrito': 'El inscrito se ha eliminado correctamente',
  Noinscritos: 'No hay ningun documento',
  'Are you sure you want to delete the image?': 'Seguro que quiere eliminar la imagen ?',
  'Are you sure you want to delete the logo?': 'Seguro que quiere eliminar el logo ?',
  'Are you sure you want to delete the faq?': 'Seguro que quiere eliminar la faq : ',
  'Are you sure you want to delete the tipoDeContacto?': 'Seguro que quiere eliminar el tipo de contacto: ',
  'Are you sure you want to delete the horari?': `Seguro que quiere eliminar el horario`,
  'caracteristicas deleted successfully': 'La característica ha sido eliminada correctamente',
  'Are you sure you want to delete the account?': 'Está seguro que desea eliminar la cuenta?',
  'Are you sure you want to delete the document?': 'Seguro que quiere eliminar el documento ?',
  'Are you sure you want to delete the profile?': 'Eliminar la cuenta',
  'Are you sure you want to delete the comunidad asociada ?': 'Está seguro que desea eliminar',

  'Dades personals': 'Datos personales',
  Inscritos: 'Inscritos',
  'Dades factura': 'Datos factura',
  Caracteristiques: 'Características',
  'Archivo adjunto': 'Archivo adjunto',
  mensajeModalBody: 'Pasara a la lista de usuarios inscritos de la comunidad como validado',
  mensajeModalBodyTarifa: 'Se actualizará la tarifa de este cliente',
  spacio: ' ',
  Guardar: 'Guardar',
  Confirmar: 'Confirmar',
  cerrada: 'Cerrada',
  abierta: 'Admite miembros',
  enLista: 'Lista de espera',
  oculta: 'Oculta',
  completa: 'Completa',
  Validat: 'Validado',
  NO_ES_ARCHIVO_CSV: 'El archivo no es un archivo CSV.',
  ARCHIVO_CSV_TIENE_FORMATO_INCORRECTO: 'El archivo CSV tiene un formato incorrecto.',
  ARCHIVO_CSV_DEMASIADO_GRANDE: 'El archivo CSV es demasiado grande.',
  ARCHIVO_DEMASIADO_GRANDE: 'El archivo CSV es demasiado grande.',
  ARCHIVO_DEMASIADO_PEQUENIO: 'El archivo CSV es demasiado pequeño.',
  ARCHIVO_TIENE_DATOS_DEMASIADO_ANTIGUAS: 'El archivo tiene datos demasiado antiguos',
  ARCHIVO_CSV_TIENE_FECHAS_DE_FORMATO_INCORRECTO: 'El archivo CSV contiene fechas con un formato incorrecto.',
  CURVA_INCORRECTA_HORAS_DUPLICADAS: 'La curva tiene horas duplicadas.',
  CURVA_INCORRECTA_FALTAN_HORAS: 'La curva tiene horas faltantes.',
  CURVA_INCORRECTA_NO_EMPIEZA_01_DE_ENERO_A_LA_1: 'La curva no comienza el 1 de enero a la 1.',
  CURVA_INCORRECTA_NO_ACABA_31_DE_DICIEMBRE_A_LAS_23: 'La curva no termina el 31 de diciembre a las 23.',
  CURVA_INCORRECTA_TIENE_VALORES_NULOS: 'La curva tiene valores nulos.',
  CURVA_TIENE_VALORES_INCORRECTOS: 'La curva tiene valores incorrectos.',
  ARCHIVO_CSV_TIENE_SEPARADOR_INCORRECTO: 'El archivo csv tiene separador incorrecto.',
  Estado: 'Estado:',
  Domesticos: 'Domesticos',
  Domestic: 'Doméstico',
  Industrial: 'Industrial',
  Particular: 'Particular',
  Propietario: 'Propietario',
  Autorizado: 'Autorizado',

  Alquiler: 'Alquiler',
  Otros: 'Otros',
  tarifa1: '2.0TD',
  tipoDeUsuario: `Tipos de usuarios`,
  Promotor: 'Promotor',
  tarifa2: '3.0TD',
  Empresa: 'Empresa',
  Potencias: 'Potencias',
  margenes: 'Margenes',
  margenMensual: 'Margen mensual (€)',
  margenKwH: 'Margen kW (€kWh)',
  tarifaIndexada: 'Tarifa indexada',
  Potencia: 'Potencia',
  Tarifa: 'Términos energía',
  'Error Uploading gallery image': 'Error al subir la imagen',
  'Error Uploading gallery Logo': 'Error al subir el logo',
  ErrorInscritos: 'Documento demasiado grande',
  'Production Guarantee calculation coefficient': 'Coeficiente cálculo Garantía de producción',
  'Guarantee coefficient': 'Coeficiente de garantía',
  Month: 'Mes',
  'Project Estimation (kWh/month)': 'Estimación proyecto (kWh/mes)',
  'Production guarantee (kWh/month)': 'Garantía producción (kWh/mes)',
  January: 'Enero',
  February: 'Febrero',
  March: 'Marzo',
  April: 'Abril',
  May: 'Mayo',
  June: 'Junio',
  July: 'Julio',
  August: 'Agosto',
  September: 'Septiembre',
  October: 'Octubre',
  November: 'Noviembre',
  December: 'Diciembre',
  enero: 'Enero',
  febrero: 'Febrero',
  marzo: 'Marzo',
  abril: 'Abril',
  mayo: 'Mayo',
  junio: 'Junio',
  julio: 'Julio',
  agosto: 'Agosto',
  septiembre: 'Septiembre',
  octubre: 'Octubre',
  noviembre: 'Noviembre',
  diciembre: 'Diciembre',
  gener: 'Enero',
  febrer: 'Febrero',
  març: 'Marzo',
  maig: 'Mayo',
  juny: 'Junio',
  juliol: 'Julio',
  agost: 'Agosto',
  septembre: 'Septiembre',
  novembre: 'Noviembre',
  desembre: 'Diciembre',
  'Generation estimate in watt hours': 'Estimación de generación en vatios hora',
  Year: 'Año',
  'Data studio saved!': 'Datos de estudio guardados!',
  'Error Updating Data studio.': 'Error al actualizar los datos',
  installationNotSelected: 'No hay ninguna instalación seleccionada',
  home: 'Home',
  charts: 'Gráficos',
  accounting: 'Contabilidad',
  sustainability: 'Sostenibilidad',
  'renovable-stamp': 'Sello renovable',
  'new contract': 'Contrato nuevo',
  Image: 'Imagen',
  Logo: 'Logo',

  Weight: 'Peso',
  Actions: 'Acciones',
  Documentos: 'Documentos',
  Delete: 'Borrar',
  Total: 'Total',
  generation: 'Generación',
  shrinking: 'Excedente',
  network: 'Red',
  selfConsumption: 'Autoconsumo',
  consumption: 'Consumo',
  energy: 'Energía',
  Tarifes: 'Tarifas',
  SelecionaTarifa: 'Seleccione la tarifa indicada',
  SelecionaImagen: 'Seleccione que tipo es la imagen:',
  Imatge: 'Imagen',
  SelecionaComunidad: 'Selecciona la comunidad',
  saving: 'Ahorro',
  costWithoutElecsum: 'Coste sin Elecsum',
  costWithElecsum: 'Coste con Elecsum',
  selfConsumptionFen: 'Autoconsumida',
  amortization: 'Amortización',
  getEnergyDataError: 'Error al cargar los datos de energía',
  'current month': 'mes en curso',
  Energy: 'Energía',
  paltfix: 'paltfix',
  platflexa2: 'platflexa2',
  platflexa3: 'platflexa3',
  'Production guarantee evolution': 'Evolución Garantía de producción',
  'production guarantee': 'Garantía de producción',
  'guarantee percentage achieved': 'Porcentaje de garantía conseguido',
  'Comercial name': 'Nombre comercial',
  getSavingDataError: 'Error al cargar los datos de ahorro',
  getAmortizationDataError: 'Error al cargar los datos de amortización',
  in: 'En',
  monthYouAmortized: 'meses has amortizado',
  ofTheInstalation: 'de la instalación',
  'see web': 'ver web',
  Consumption: 'Consumo',

  'Renewable energy production': 'Producción energía renovable',
  'Renewable energy consumption': 'Consumo energía renovable',
  'current year': 'año en curso',
  'accumulated total': 'total acumulada',
  'contact us': 'contáctanos',
  'If you want more information about': 'Si quieres más información sobre',
  'photovoltaic installations': 'instalaciones fotovoltaicas',
  '"Elecsum Green certifies the generation and / or 100% renewable energy consumption, See the details below"':
    '“Elecsum Green certifica la generación y/o consumo de energía 100% renovable. Consulta más abajo los detalles.”',
  'Updated information a': 'Información actualizada a ',
  'certifies that': ' certifica que ',
  'is a customer of': ' es cliente de ',
  'as of today': ' a fecha de hoy ',
  'ELECTRA CALDENSE ENERGIA S L is provided exclusively with 100% renovable energy':
    'ELECTRA CALDENSE ENERGIA S.A. se provee exclusivamente de energía 100% renovable.',
  'See CNMC certificate': 'Ver certificado de la CNMC',
  about: 'sobre ',
  'Our commitment to the Environment': 'Nuestro compromiso con el medio ambiente',
  noEmmited: 'no emitido',
  trees: 'árboles',
  planted: 'plantados',
  viewMore: 'Ver más',
  myContracts: 'Mis contratos',
  invoiceTotal: 'Total factura',
  invoice: 'Factura',
  invoices: 'Facturas',
  invoice_number: 'Número de factura',
  invoice_date: 'Fecha factura',
  errorGettingInvoiceInfo: 'Error solicitando factura',
  errorDownloadInfo: 'Error en descargar la factura',
  period: 'Periodo',
  documents: 'Documentos',
  egreen: 'Elecsum Green',
  projectEstimation: 'Estimación del proyecto',
  study: 'Estudio',
  Global: 'Global',
  activeClient: 'Cliente activo',
  year: 'año',
  productionGuarantee: 'Garantía de Producción',
  actualYear: 'Año en curso',
  goalAccomplished: 'del objetivo de producción conseguido!',
  'Are you sure you want to delete the new?': '¿Estás seguro de que deseas eliminar la noticia?',
  'Are you sure you want to delete the característica?': '¿Estás seguro de que deseas eliminar la característica?',

  'New deleted successfully': 'Noticia eliminada correctamente',
  'Error deleting new': 'Error al eliminar la noticia',
  'Client successfully updated': 'Cliente actualizado con éxito',
  Cost: 'Coste',
  'Installation cost': 'Coste de la instalación',
  noDataObtained: 'Sin datos',
  downloadInvoice: 'Descargar factura',
  download: 'Descargar',
  Comunitat: 'Comunidad',
  'La meva': 'La mía',
  daily: 'Diario',
  monthly: 'Mensual',
  annual: 'Anual',
  historical: 'Histórico',
  surplus: 'Excedente',
  differenceInst: 'Diferencia Instalaciones radio',
  diffGuarantee: 'Diferencia Garantía de producción',
  estimateProject: 'Valor estimado del proyecto',
  differenceEstimate: 'Diferencia Valor estimado proyecto',
  JAN: 'ENE',
  FEB: 'FEB',
  MAR: 'MAR',
  APR: 'ABR',
  MAY: 'MAY',
  JUN: 'JUN',
  JUL: 'JUL',
  AUG: 'AGO',
  SEP: 'SEP',
  OCT: 'OCT',
  NOV: 'NOV',
  DEC: 'DIC',
  'My Profile': 'Mi perfil',
  'My Info': 'Mis datos',
  'Change Password': 'Cambio de contraseña',
  Surname: 'Apellidos',
  NIF: 'NIF / DNI',
  Password: 'Contraseña',
  'Actual Password': 'Contraseña actual',
  'New Password': 'Nueva contraseña',
  'Repeat New Password': 'Repetir la nueva contraseña',
  'min 8 characters': 'mín. 8 caracteres',
  'Password Changed': 'Contraseña cambiada correctamente',
  'Delete Cuenta': 'Eliminar el usuario',
  'Delete Account': 'El usuario ha sido eliminado correctamente',
  'Email Cuenta': 'Actualizar',
  'Configuracion no tine informe.': 'No iniciada',
  ENEJECUCION: 'En ejecución',
  COMPLETADO: 'Completado',
  NOEJECUTADO: 'No ejecutado',
  COMPLETADOCONERRORES: 'Completado con errores',
  errorDeleteAccount: 'Se ha producido un error al eliminar el usuario',
  errorNewPasswordNotValid: '¡Error! Contraseña no válida.',
  description: 'Descripción',
  date: 'Fecha',
  hour: 'Hora',
  status: 'Estado',
  contribution: 'Contribución',
  instalations: 'instalaciones',
  parisAgreement2015: 'Acuerdo de energía limpia de la UE',
  whatAreParisAgreementObjectives: '¿Cuáles son los objetivos del Clean Energy package en 2030?​',
  UEObjective: 'Objetivo de reducción de CO2 Unión Europea 2030',
  ESObjective: 'Objetivo de reducción de CO2 España 2030',
  milionTones: 'millones de toneladas de CO2',
  emisions: 'Emisiones',
  noEmmitedYear: 'no emitidas / año',
  myContribution: 'Mi contribución',
  alerts: 'Alertas',
  alerts_pending: 'Alertas pendientes',
  spain: 'ESPAÑA',
  from: 'del',
  to: 'a',
  Data: 'Datos',
  'Study data': 'Estudio',
  Home: 'Home',
  activeCups: 'Dirección',
  Charts: 'Gráficos',
  Sustainability: 'Sostenibilidad',
  Logout: 'Cerrar Sesión',
  'Sign in': 'Iniciar Sesión',
  'Data  Client': 'Datos cliente',
  day: 'día',
  month: 'mes',
  historic: 'histórico',
  updateSuccess: 'Datos actualizados correctamente.',
  desde: 'Desde',
  search: 'Buscar Instalación, Cliente o CUPS',
  guaranteeEvolution: 'Evolución garantía',
  guarantees: 'Garantiza',
  'Error Updating Installation': 'Error al actualizar la instalación',
  Others: 'Otros',
  'Instalation titularity percentage': 'Porcentaje titularidad instalación',
  'Coste acceso': 'Coste acceso',
  Address: 'Dirección',
  emmited: 'emitidas',
  yes: 'Sí',
  no: 'No',
  '2.0TD': '2.0TD',
  '3.0TD': '3.0TD',
  query: 'Consulta',
  getElecsumGreenError: 'Error al cargar identificador Elecsum Green',
  thisMonthSavingWithElecsum: 'Este mes, con Elecsum hubieras conseguido',
  getPotencialSaving: 'Consigue el ahorro potencial que te mereces',
  accumulatedSavings: 'Ahorro acumulado',
  emmitedHabYear: 'emitidas por habitante / año',
  checkAndShareElecsumGreen: 'Consulta aquí tu sello verde y compártelo',
  'Set New Password': 'Configura el Nuevo Password',
  newPasswordAtLeast8: 'La nueva contraseña debe tener un mínimo de 8 caracteres',
  invalidToken: 'Token inválido o caducado',
  errorRecoverPassword: 'Error al intentar cambiar la contraseña',
  contactUs: 'Contacta con nosotros',
  totalAccumulated: 'Total acumulado',
  installationPowerTotal: 'Potencia total instalación',
  sharedEnergy: 'Generación repartida',
  doing: 'En curso',
  estandard: 'Estandard',
  done: 'Resuelta',
  errorGetAlertsData: 'Error al cargar la información de alertas, inténtalo de nuevo más tarde',
  errorModifyingAlert: 'Error al modificar la alerta, inténtalo de nuevo más tarde',
  pending: 'Pendiente',
  alertsList: 'Listado de alertas',
  noAlertsMessage: 'No hay alertas para mostrar',
  loginBadCredentials: 'Error de autenticación, credenciales incorrectas.',
  documents_no_documents: 'No se han encontrado documentos para este CUPS.',
  CIM: {
    DashboardPage: {
      errors: {
        contractSummaryError: 'Error obteniendo datos Resumen de Contrato',
        maximetersError: 'Error obteniendo los datos de Maxímetros',
      },
    },
    InvoiceSimulationPage: {
      Commons: {
        day: 'día',
        days: 'días',
        simulationInterval: 'Intervalo de simulación',
        dateInterval: 'Intervalo de fechas',
        period: 'Periodo',
        description: 'Descripción',
        total: 'Total',
        simulate: 'Simular',
        reset: 'Reiniciar',
      },
      Powers: {
        p1: 'Potencia 1',
        p2: 'Potencia 2',
        p3: 'Potencia 3',
        p4: 'Potencia 4',
        p5: 'Potencia 5',
        p6: 'Potencia 6',
      },
      ResumenSection: {
        title: 'Total factura',
        base: 'Base',
        iva: 'Iva',
        total: 'Importe total',
      },
      DatosContratacionSection: {
        title: 'Datos de contratación',
        tarifa: 'Tarifa de acceso',
        potencia: 'Potencia contratada',
        titular: 'Titular',
        domicilio: 'Domicilio del suministro',
      },
      Lecturas: {
        title: 'Lecturas',
        active: 'Activa',
        activeTotal: 'Total activa',
        reactive: 'Reactiva',
        reactiveTotal: 'Total reactiva',
        maximeter: 'Maxímetro',
        consume: 'Consumo',
      },
      Consumos: {
        title: 'Consumos',
        powerTerm: 'Término de potencia',
        powerTotal: 'Total potencia',
        energyTerm: 'Término de energía',
        energyTotal: 'Total energía',
        reactiveTerm: 'Energía reactiva',
        reactiveTotal: 'Total reactiva',
        powerExcessTerm: 'Exceso de potencia',
        powerExcessTotal: 'Total exceso',
        electricityTax: 'Impuesto sobre la electricidad',
        hardwareItems: 'Equipos de medición',
        excess: 'Excedentes',
        excessTotal: 'Total excedentes',
      },
      Errores: 'No disponemos de datos para realizar el cálculo solicitado.',
    },
    CIMDeviceInfoPage: {
      title: 'Equipos de medición',
      medida: 'Medida',
      numeroDeContador: 'Número de contador',
      numeroDeModem: 'Número de módem',
      propiedad: 'Propiedad',
      telegestionado: 'Telegestionado',
      tipoDeMedicion: 'Tipo de medición',
      errorFetchingData: 'Error obteniendo los datos de equipos de medición.',
    },
    DataExportPage: {
      picker_label: 'Exportación de datos',
      errorFetching: 'Error obteniendo los datos de exportación',
    },
    ClosuresPage: {
      pickerLabel: 'Intervalo de cierre',
      errorFetching: 'Error obteniendo los datos de cierres.',
      noClosures: 'No hay cierres disponibles',
    },
    PowerOptimizationPage: {
      errorFetching: 'Error obteniendo los datos de optimización',
      interval: 'Intervalo de optimización',
      loadingTitle: 'Consultando datos de teledetección del modem asociado.',
      loadingSubtitle: 'Este proceso puede durar unos instantes...',
      noData: 'No disponemos de datos para realizar el cálculo solicitado.',
      SummarySection: {
        title: 'Resumen de todas las facturas',
        totalEnergy: 'Total Energía',
        totalPower: 'Total Potencia',
        totalReactive: 'Total Reactiva',
        total: 'Total',
      },
      OptimizationSection: {
        title: 'Optimización de las potencias',
        period: 'Periodo',
        optimal: 'Potencia óptima',
        actual: 'Potencia actual',
        no_data: 'No hay datos de optimización disponibles.',
      },
      SavingSection: {
        title: 'Ahorro',
        savingLabelPercent: 'Consiguiendo un ahorro del',
        savingLabelImport: 'Consiguiendo un ahorro de',
        iva: 'IVA incluido',
      },
    },
    MeasurementRegistersPage: {
      intervalLabel: 'Rango de fechas',
      typeLabel: 'Tipo de gráfico',
      agregated: 'Acumulado',
      maximeter: 'Maxímetros',
      grouping: 'Agrupación por',
      all: 'Todo',
      hour: 'Hora',
      day: 'Dia',
      week: 'Semana',
      month: 'Mes',
      active: 'Activa',
      hired: 'Contratada',
      Activa: 'Activa',
      Capacitiva: 'Capacitiva',
      Inductiva: 'Inductiva',
      Exportada: 'Exportada',
      Neta: 'Neta',
      Excedent: 'Excedente',
    },
  },
  MaximetersWidget: {
    title: 'Últimos maxímetros',
  },
  ComercializationSummaryWidget: {
    title: 'Resumen comercialización',
    tarifa: 'Tarifa',
    year: 'Año',
    tPotencia: 'Términos potencia',
    tEnergia: 'Términos energía',
  },
  ContractResumCard: {
    title: 'Resum de contracte ATR',
    solar: 'Fotovoltaica',
    motor: 'Motor',
    eolica: 'Eólica',
    bateria: 'Batería',
    potencia: 'Potencia contratada',
    cliente: 'Código cliente',
    tarifa: 'Tarifa',
    tarifaComercial: 'Tarifa comercializadora',
    porcentajeElectricidadBonificacion: 'Porcentaje bonificación',
    porcentajeExcencionImpuesto: 'Porcentaje exención',
    instalaciones: 'Tipo de instalación',
  },
  PowerOptimizationWidget: {
    title: 'Optimización de potencia',
    label: 'Optimización',
  },
  MensualConsumptionWidget: {
    title: 'Registro lecturas de contador',
    titleTarifa: 'Registro consumos',
  },
  InvoiceCard: {
    status: {
      pendiente: 'Pendiente',
      pagada: 'Pagada',
      impagada: 'Impagada',
    },
  },
  RegisteredUsersWidget: {
    title: 'Usuarios',
    label: 'Usuarios registrados',
    link: 'Ver más',
  },
  UsersPage: {
    goDashboard: 'Volver a dashboard',
    tableTitle: 'Listado de Usuarios',
    gestionRols: 'Gestionar Roles',
    csvLabel: 'Exportar en CSV',
    userLabel: 'Añadir usuario',

    tableHeaders: {
      nif: 'NIF',
      alias: 'Nombre y Apellidos',
      email: 'Email',
      apps: 'Sistema App',
      tipoDeUsuario: `Tipos de usuarios`,
    },
  },
  DashboardPage: {
    alerts_fetching_error: 'Error al cargar la información de alertas, inténtalo de nuevo más tarde',
    users_fetching_error: 'Error al cargar la información sobre usuarios, inténtalo de nuevo más tarde.',
    users_no_data: 'No hay datos de usuarios',
    nodata_grafic: 'No hay datos',
    cups_fetching_error: 'Error al cargar la información sobre CUPS, inténtalo de nuevo más tarde.',
    cups_no_data: 'No hay datos de CUPS',
    comunity_fetching_error: 'Error al cargar la información de las comunidades, inténtalo de nuevo más tarde.',
    solicitud_fetching_error:
      'Error al cargar la información de las solicitudes de actualización de tarifa, inténtalo de nuevo más tarde.',
    comunity_no_data: 'No hay datos de ninguna comunidad',

    RegisteredCUPSWidget: {
      title: 'CUPS',
      p1: 'CUPS registrados',
    },
    RegisteredInstallationWidget: {
      title: 'Instalaciones',
      p1: 'Instalaciones',
    },
    ComunidadesDashboardWidget: {
      title: 'Comunidades',
      more: 'Ver más',
      comunities: 'Comunidades',
      pending: 'Inscritos pendientes de validar',
      viewmore: 'Ver más',
    },
    TarifasDashboardWidget: {
      title: 'Solicitudes de actualización de tarifa',
      more: 'Ver más',
      pending: 'Solicitudes de actualización de tarifa pendientes de validar',
      viewmore: 'Ver más',
    },

    MatcherDashboardWidget: {
      title: 'Matcher',
      more: 'Ver más',
      pending: 'Matcher',
      viewmore: 'Ver más',
    },
  },
  DateIntervalPicker: {
    start_date: 'Fecha inicial',
    end_date: 'Fecha final',
  },
  AlertsPage: {
    goDashboard: 'Volver a dashboard',
  },
  Sidenav: {
    Client: {
      Home: 'Home',
      Charts: 'Gráficos',
      Invoices: 'Facturas',
      Documents: 'Documentos',
      Sustainability: 'Sostenibilidad',
    },
    Tecnic: {
      User_Data: 'Datos',
      User_CUPS: 'CUPS',
      User_Egreen: 'Elecsum Green',
      CUPS_Detail: 'Detalle',
      CUPS_Dashboard: 'Dashboard',
      Factura: 'Factura',

      Installation_Clients: 'Clientes',
      Installation_Installation: 'Instalación',
      Installation_Study: 'Estudio',
      Installation_Production: 'Producción',
      Communities_Detail: 'Detalle',
      Communities_Members: 'Miembros',
      Communities_Enrolled: 'Inscritos',
      Communities_Analytics: 'Análisis',
      Communities_Customization: 'Personalización',
      Communities_Promotores: 'Promotores',
      Matcher_Perfil: 'Perfiles de consumo',
      Matcher: 'Matcher',
      Preferencias: 'Preferencias',
      RolesPermisions: 'Roles',
      CupsAsociados: 'Cups asociados',
    },
    CIM: {
      cim: 'CIM',
      dashboard: 'Dashboard',
      registers: 'Registros de medición',
      simulation: 'Simulación de factura',
      optimization: 'Optimización de potencia',
      devices: 'Equipos de medición',
      closures: 'Cierres',
      export: 'Exportación de datos',
    },
    UpdateTarifa: {
      mas: 'Más',
      updateTarifa: 'Actualizar tarifa',
    },
    cupsAutorizados: 'CUPS autorizados',
  },
  UpdateTarifaPage: {
    cups: 'CUPS',
    descripcion: 'Sube tu última factura e introduce los siguientes datos:',
    industrial: 'Industrial',
    domestico: 'Doméstico',
    tipoSolicitante: 'Tipo de Solicitante',
    indexado: 'Indexado',
    noIndexado: 'No indexado',
    regulada: 'Tarifa indexada (PVPC)',
    añadeImagen: 'Adjunta tu última factura de energia disponible',
    margeMensual: 'Margen mensual (€/mes)',
    margeKw: 'Margen energía (€/kWh)',
    compensacion: 'Compensación de excedentes',
    compensacionImporte: 'Importe',
    preuTerme: 'Términos de energía (en €/kWh)',
    margenesTecnico: 'Márgenes mensual/kWh',
    margenes: 'Márgenes de beneficio',
    consumPunta: 'Precio punta',
    consumPla: 'Precio llano',
    consumVall: 'Precio valle',
    p1: 'Precio periodo 1',
    p2: 'Precio periodo 2',
    p3: 'Precio periodo 3',
    p4: 'Precio periodo 4',
    p5: 'Precio periodo 5',
    p6: 'Precio periodo 6',
    dades: 'Solicitud de actualización de tarifa',
    toastCorrecto: 'Solicitud de actualitzación de tarifa enviada correctamente',
    toastCorrectoGuardada: 'Solicitud de actualitzación de tarifa guardada correctamente',
    toastFallido: 'Error en enviar la solicitud de actualización de tarifa',
    enviar: 'Enviar',
    enviarSeguro: 'Seguro que quiere enviar la solicitud para actualizar su tarifa ?',
    cupInfo:
      'Introduce el CUPS con el que quieres entrar en la comunidad solar, verificarlo nos ayudará a proporcionarte unos datos más exactos:',
  },
  HeaderSearchInput: {
    inputPlaceholder: 'Búsqueda por instalación, CUPS, usuario',
    inputPlaceholderCupsAsociado: 'Búsqueda por CUPS',
    inputPlaceholderMatcher: 'Búsqueda por instalación',
    filterInstallation: 'Instalación',
    filterCUPS: 'CUPS',
    filterUser: 'Usuario',
    filterCommunity: 'Comunidad',
  },
  UsuarioDetailPage: {
    UserData: {
      title: 'Datos Usuario',
      titleSmtp: 'Datos email SMTP',
      labels: {
        name: 'Nombre',
        surname: 'Apellidos',
        email: 'Email',
        nif: 'NIF',
      },
      promotorLabels: {
        smtpHost: 'Host',
        smtpPort: 'Puerto',
        username: 'Email',
        password: 'Contraseña',
        useTls: 'TLS',
        useSsl: 'SSL',
        telefonoDeContacto: 'Teléfono de contacto',
      },
    },
    DevicesData: {
      title: 'Dispositivos registrados',
      noData: 'El usuario no tiene dispositivos registrados',
      headers: {
        devices: 'Dispositivos',
        system: 'Sistema App',
      },
    },
    Promotor: {
      title: 'Comunidades asociadas',
      noData: 'Este promotor no tiene comunidades asociadas',
      titleComunidadPromotor: 'Promotores asociados ',
      noDataComunidadPromotor: 'Esta comunidad no tiene promotores asociados',
      errorContra: 'La contraseña no coincide',
      errorEmail: 'El correo es inválido',
      headers: {
        devices: 'Comunidad',
        Promotors: 'Promotores',
        principal: 'Principal',
      },
    },
  },
  UsuarioCUPSPage: {
    title: 'Listado de CUPS',
    noData: 'El usuario no tiene CUPS vinculados.',
    headers: {
      cups: 'CUPS',
      address: 'Domicilio',
      city: 'Población',
    },
    viewInstallation: 'Ver instalación',
  },
  SelectUserType: {
    label: 'Tipo de cliente',
    basic: 'Básico',
    premium: 'Prémium',
  },
  CUPSDetailPage: {
    viewInstallation: 'Ver instalación asociada',
    viewUser: 'Ver usuario asociado',
    labels: {
      monthly: 'Mensual',
      yearly: 'Anual',
    },
  },
  SignUpPage: {
    steps: {
      user: 'Usuario',
      email: 'Email',
      password: 'Contraseña',
    },
    success: {
      title: 'Usuario registrado correctamente',
      subtitle: 'Ya puedes iniciar sesión con tu nuevo usuario.',
      login: 'Iniciar sesión',
    },
    form: {
      nifLabel: 'NIF / ID',
      nifPlaceholder: 'NIF / ID titular del CUPS',
      cupsLabel: 'CUPS',
      cupsPlaceholder: 'Identificador CUPS',
      cupsHelper: {
        title: '¿No encuentras tu CUPS?',
        p1: 'El número CUPS, es el código alfanumérico que identifica cada casa o negocio, tanto para la red de electricidad como de la red de gas natural.',
        p2: 'Está compuesto por dos letras iniciales que indican el código del país, en el caso de España: “ES”, seguido de 20 o 22 caracteres.',
      },
      passwordLabel: 'Contraseña',
      passwordPlaceholder: 'Contraseña de acceso',
      passwordRepeatLabel: 'Repetir contraseña',
      passwordRepeatPlaceholder: 'Repetir contraseña de acceso',
      continue: 'Continuar',

      back: 'Atrás',
      finish: 'Finalizar registro',
    },
    errors: {
      submitEmail: 'Email ya registrado en el sistema',
      submitNIF: 'NIF ya registrado en el sistema',
      cupsRequired: 'Campo CUPS obligatorio.',
      nifRequired: 'Campo NIF obligatorio.',
      cupsNIFNoExist: 'El NIF o CUPS no existe en el sistema',
      emailRequired: 'Campo email obligatorio.',
      emailBad: 'El campo no tiene formato de email.',
      emailNotSame: 'Las direcciones introducidas no coinciden',
      passwordLength: 'Mínimo 8 caracteres',
      passwordRequired: 'Campo contraseña obligatorio.',
      passwordNotSame: 'Las contraseñas introducidas no coinciden.',
    },
  },

  LoginPage: {
    form: {
      idLabel: 'NIF / ID',
      idPlaceholder: 'NIF / ID',
      passwordLabel: 'Contraseña',
      passwordPlaceholder: 'Contraseña de acceso',
      login: 'Iniciar sesión',
      forgotPassword: '¿Has olvidado tu contraseña?',
      register: 'Regístrate',
      notRegistered: '¿No tienes una cuenta?',
    },
    errors: {
      badCredentials: 'Error de autenticación, credenciales incorrectas.',
      idRequired: 'Campo identificador obligatorio.',
      passwordRequired: 'Campo contraseña obligatorio.',
    },
  },
  NotFound404Page: {
    title: 'Error 404: Página solicitada no encontrada',
    match: 'Ninguna coincidencia para: ',
    home: 'Volver a página principal',
  },
  ForgotPasswordPage: {
    helper: 'Introduce el email asociado a tu cuenta para recuperar la contraseña.',
    helperNif: 'Introduce el NIF asociado a tu cuenta para recuperar la contraseña.',

    recover: 'Recupera la contraseña',
    successTitle: 'Solicitud enviada',
    successMsg: 'Si estás registrado en el sistema, se te enviará un correo para recuperar la contraseña.',
    goBack: 'Volver',
  },
  CUPSListPage: {
    error: {
      noData: 'No se han encontrado resultados.',
    },
    filters: {
      title: 'Listado de CUPS',
      searchPlaceholder: 'Filtrar CUPS por texto',
      autoconsumo: 'Autoconsumo',
      mantenimiento: 'Mantenimiento',
      garantia: 'Garantía',
      years: 'Años de garantía',
      industrial: 'Industrial',
      allYears: 'Todos',
    },
    paginacion: {
      page: 'Página',
    },
    headers: {
      cups: 'CUPS',
      name: 'Nombre / Razón social',
      domicilio: 'Domicilio',
    },
    row: {
      verDetalle: 'Ver',
      verInstalacion: 'Ver instalación',
      autoconsumo: 'Autoconsumo',
      mantenimiento: 'Mantenimiento',
      garantia: 'Garantía',
      yearsGarantia: 'Años de garantía',
    },
  },
  InstallationListPage: {
    error: {
      noData: "No s'han trobat cap resultat.",
    },
    title: 'Listado de Instalación',
    filters: {
      title: 'Llistat de CUPS',
      searchPlaceholder: 'Filtrar instalaciones por texto',
      autoconsumo: 'Autoconsum',
      mantenimiento: 'Manteniment',
      garantia: 'Garantia',
      industrial: 'Industrial',
      years: 'Anys de garantia',
      allYears: 'Tots',
    },
    paginacion: {
      page: 'Pàgina',
    },
    headers: {
      idELECSUM: 'idELECSUM',
      CAU: 'CAU',
      CILL: 'CILL',
      NombreInstalacion: 'Nombre instalacion',
      Nombre: 'Nombre y Apellidos',
      Domicilio: 'Domicilio',
    },
    row: {
      verDetalle: 'Veure',
      verInstalacion: 'Veure instal·lació',
      autoconsumo: 'Autoconsum',
      mantenimiento: 'Manteniment',
      garantia: 'Garantia',
      yearsGarantia: 'Anys de garantia',
    },
  },
  ComunidadesListPage: {
    title: 'Listado de comunidades',
    searchPlaceholder: 'Buscar comunidad',

    Places: 'Plazas disponibles',
    headers: {
      name: 'Nombre',
      location: 'Ubicación',
      inscription: 'Término de inscripción',
      status: 'Estado',
    },
    Inscrits: 'Inscritos',
    detail: 'Ver',
    page: 'Página',
    estado: {
      abierta: 'Admite miembros',
      enLista: 'Lista de espera',
      oculta: 'Oculta',
      cerrada: 'Cerrada',
      completa: 'Completa',
      titulo: 'Estado: ',
    },
    tipoDeComunidad: {
      estandard: 'Estandard',
      otc: 'OTC',
      titulo: 'Tipo de Comunidad: ',
      checkSignaturitPromotor: '¿Enviamos una copia del documento firmado en signaturit  a promotor?',
    },
  },
  ComunidadesDetallePage: {
    linkCopied: 'Enlace copiado al portapapeles del genérico',
    linkCopiedPromotor: 'Enlace del promotor copiado al portapapeles',
    copyUrl: 'Copiar la URL del genérico',
    copyUrlPromotor: 'Copiar URL del promotor',
    copyUrlComunidad: 'Copiar URL de la comunidad',
    linkCopiedComunidad: 'Enlace copiado al portapapeles',
    imageData: {
      title: 'Imagen principal',
      Logo: 'Logo comunidad',
    },
    detailData: {
      title: 'Ficha Detalle',
      labels: {
        name: 'Nombre',
        title: 'Titol',
        domicilio: 'Ubicación',
        descripcion: 'Descripción',
        poblacion: 'Población',
        codigoPostal: 'Código postal',
        provincia: 'Provincia',
        date: 'Plazo de inscripción finalizado',
        tipologia: 'Tipología',
        promocion: 'Promoción',
        generacion: 'Generación instalable',
        potenciainstalable: 'Potencia instalable',
        titularPoliticaPrivacidad: 'Titular de la política de privacidad',
        limitacionNumeroInscripciones: 'El número de límites de inscripciones',
        co2: 'Ahorro CO2',
        arbres: 'Árboles plantados',
        members: 'Número max. de miembros',
        estado: ' Marca como completado',
        radioAlcance: `Radio de alcance (en metros)`,
        footer1: 'footer1',
        footer2: 'footer2',
        consumoAnualPromedio: 'Consumo Anual Promedio',
      },
      tipologiaOptions: {
        industrial: 'Industrial',
        residencial: 'Residencial',
      },
      promocionOptions: {
        publica: 'Pública',
        privada: 'Privada',
        privadaYPrivada: 'Pública y privada',
      },
      curva: {
        subidaCorrectamente: 'Curva de generacion subida correctamente',
        subidaIncorrectamente: 'Curva de generacion subida incorrectamente',
        subidaCorrectamenteConsumo: 'Curva de consumo subida correctamente',
        subidaIncorrectamenteConsumo: 'Curva de consumo subida incorrectamente',
        exportCurva: 'Descargar curva generación',
        exportCurvaConsumo: 'Descargar curva de consumo',
        yaHayCsv: 'Ya existe un CSV',
      },
    },
    members: {
      title: 'Miembros',
      labels: {
        name: 'Nombre',
        efficiency: 'Eficiencia',
        status: 'Estado',
        admitted: 'Admitido',
        verDetalle: 'Ver',
      },
    },
  },
  ComunitiesEnrolledListPage: {
    title: 'Listado de inscritos',
    searchPlaceholder: 'Buscar',
    headers: {
      name: 'Nombre inscrito',
      community: 'Comunidad',
      status: 'Estado',
      direccion: 'Dirección',
      telefono: 'Teléfono',
      notResult: 'No tiene ningún inscrito',
      CUPS: 'Cups',
      Data: `Inscripción`,
      nombreComunidad: 'Comunidad',
    },
    tagPending: 'Pendiente',
    validate: 'Validar',
    page: 'Página',
    notResult: 'No hay ninguno inscrito asociado',
    detalles: 'Ver',
    PendientaValidar: 'Pendiente de validar',
  },
  TarifasListPage: {
    title: 'Solicitud de actualización de tarifa',
    datosSolicitud: 'Datos solicitud',
    datosTarifa: 'Datos tarifa',
    searchPlaceholder: 'Buscar',
    headers: {
      name: 'Nombre',
      nif: 'Nif',
      notResult: 'No hay ninguna solicitud de cambio de tarifa',
      CUPS: 'Cups',
      comunidad: 'Comunidad',
      errorComunidad: 'Error al obtener el nombre de la comunidad',
      fecha: `Fecha`,
    },
    tagPending: 'Pendiente',
    validate: 'Validar',
    page: 'Página',
    notResult: 'No hay ninguna solicitud de cambio de tarifa',
    detalles: 'Ver',
    PendientaValidar: 'Pendiente de validar',
  },
  MatcherListPage: {
    title: 'Configuraciones',
    datosSolicitud: 'Datos solicitud',
    datosTarifa: 'Datos tarifa',
    searchPlaceholder: 'Buscar',
    add: 'Añadir configuración',
    addMember: 'Añadir un miembro',
    loadMember: 'Cargar miembros desde csv',
    updateMember: 'Actualitzar un miembro',
    addPerfilConsumo: 'Añadir la curva de consumo en formato .csv',
    addPerfilGeneracion: 'Añadir la curva de generación en formato .csv',
    loadPerfilConsumo: 'Añadir los miembros a partir de un csv',
    linea: 'línea',
    headers: {
      name: 'Nombre',
      nif: 'Nif',
      notResult: 'No hay ninguna solicitud de cambio de tarifa',
      CUPS: 'Cups',
      comunidad: 'Comunidad',
      errorComunidad: 'Error al obtener el nombre de la comunidad',
      fecha: `Fecha`,
      report: 'Informe',
      numberMember: 'Miembros',
    },
    addMatcher: {
      description: 'Añadir una configuración al Matcher',
      comunidad: 'Selecciona comunidad',
      selectInscritos: 'Selecciona inscritos',
      deseaInscrito: 'Desea cargar los datos de un inscrito ?',
      selectPerfil: 'Selecciona perfil de consumo',
      generacio: 'Generación solar',
      miembros: 'Lista de miembros',
      generacioTitle: 'Sube aquí la curva de generación',
      generacioDesc: 'Sube aquí la curva de generación solar',
      generacioDescExist: '¿Deseas actualizar la curva de generación solar?',
      exportGeneracioDescExist: '¿Deseas descargar la curva de generación solar?',

      generacioSolar: 'Generación solar',
      subirCurva: 'Sube aquí la curva de consumo',
      comportamiento: 'Configuración comportamiento',
      betamax: 'βmax',
      betamin: 'βmin',
      betastep: 'βstep',
      maxMiembros: 'Maximizar',
      minMiembros: 'Minimizar',
      consumo: 'Consumo',
      name: 'Nombre',
      apellidos: 'Apellidos',
      nif: 'Nif',
      cups: 'CUPS',
      seleccionado: 'Seleccionado',
      vip: 'Vip',
      preferent: 'Preferente',
      perfilConsumo: 'Perfil de consumo',
    },
    informe: {
      estadoInforme: 'Estado del informe: ',
      tarda: 'Tiempo de espera aproximado: hasta 5 minutos',
      miembroExcluido: 'Miembros excluidos',
      miembroAceptado: 'Miembros aceptados',
      listaCombinacion: 'Lista de combinaciones',
      lanzarInforme: 'Generar informe',
      fechaInforme: 'Fecha informe',
      export: 'Exportar informe (.csv)',
      exportActual: 'Exportar informe actual (.csv)',
      ExportListaDis: 'Exportar betas (.txt)',
    },
    listaCombinacion: {
      numeroMiembros: 'Miembros',
      autoconsumoEuro: 'Autoconsumo €',
      excedenteEuro: 'Excedente €',
      ahorrosEuro: 'Ahorro €',
      autoconsumoPorcentaje: 'Autoconsumo %',
      excedentePorcentaje: 'Excedente %',
      esLaOptima: 'Óptimo',
    },
    tagPending: 'Pendiente',
    validate: 'Validar',
    page: 'Página',
    notResult: 'No hay ninguna solicitud de cambio de tarifa',
    detalles: 'Ver',
    PendientaValidar: 'Pendiente de validar',
    mensajeModalDelete: 'Está seguro que quiere eliminar el perfil:',
    mensajeModalDuplicar: '¿Esta seguro que quiere duplicar la configuración',
    mensajeModalDuplicarAdd: 'Añade un nombre diferente al actual',
  },
  PerfilListPage: {
    titleDetalle: 'Perfil de consumo ',
    title: 'Perfiles de consumo',
    datosSolicitud: 'Datos solicitud',
    datosTarifa: 'Datos tarifa',
    searchPlaceholder: 'Buscar',
    add: 'Añadir un perfil',
    addMember: 'Añadir un perfil de consumo',
    addPerfilConsumo: 'Añadir la curva de consumo en formato .csv',
    showPerfilConsumoCsv: 'Curva de consumo en formato .csv',
    headers: {
      name: 'Nombre',
      nif: 'Nif',
      notResult: 'No hay ninguna solicitud de cambio de tarifa',
      CUPS: 'Cups',
      comunidad: 'Comunidad',
      errorComunidad: 'Error al obtener el nombre de la comunidad',
      fecha: `Fecha`,
      report: 'Informe',
      numberMember: 'Miembros',
    },
    addMatcher: {
      description: 'Añadir una configuración al Matcher',
      comunidad: 'Selecciona comunidad',
      generacio: 'Generación solar',
      miembros: 'Lista de miembros',
      generacioDesc: 'Sube aquí la curva de generación solar',
      comportamiento: 'Configuración comportamiento',
      betamax: 'βmax',
      betamin: 'βmin',
      betastep: 'βstep',
      maxMiembros: 'Maximizar miembros',
      minMiembros: 'Minimizar miembros',
      name: 'Nombre',
      apellidos: 'Apellidos',
      nif: 'Nif',
      cups: 'CUPS',
      seleccionado: 'Seleccionado',
      vip: 'Vip',
      preferent: 'Preferente',
      exportGeneracioDescExist: '¿Deseas descargar la curva de consumo?',
    },
    actions: {
      ver: 'Ver',
      delete: 'Eliminar',
    },
    informe: {
      numeroDeMiembrosSelecionados: 'Número de miembros seleccionados',
      numeroDeMiembrosAceptados: 'Número de miembros aceptados',
      generacion: 'Generación',
      consumo: 'Consumo',
      autoConsumo: 'Autoconsumo',
      excedente: 'Excedente',
      autoConsumoEuro: 'Autoconsumo en Euros',
      miembroExcluido: 'Miembros excluidos',
      miembroAceptado: 'Miembros aceptados',
      numeroMiembros: 'Miembros',
      autoconsumoEuro: 'Autoconsumo €',
      excedenteEuro: 'Excedente €',
      ahorrosEuro: 'Ahorro €',
      autoconsumoPorcentaje: 'Autoconsumo %',
      excedentePorcentaje: 'Excedente %',
      esLaOptima: 'Óptimo',
    },
    labels: { descripcion: 'Descripción' },

    tagPending: 'Pendiente',
    validate: 'Validar',
    page: 'Página',
    notResult: 'No hay ninguna solicitud de cambio de tarifa',
    detalles: 'Ver',
    PendientaValidar: 'Pendiente de validar',
    AddPerfilesConsumo_fetching: 'Guardado Correctamente',
    perfilesConsumo_fetching_error: 'Error al cargar la información de los perfiles de consumo.',
    AddPerfilesConsumo_fetching_error: 'Error al crear el perfil de consumo.',
    EditPerfilesConsumo_fetching_error: 'Error al cargar la información del perfil de consumo.',
    EliminarPerfilesConsumo_fetching_error: 'Error en eliminar el perfil de consumo.',
    EliminarPerfilesConsumo_fetching: 'Eliminado correctamente',
    UpdatePerfilesConsumo_fetching: 'Perfil actualizado con éxito',
    UpdatePerfilesConsumo_fetching_error: 'Error en actualizar el perfil de consumo.',
    ConsumoAnual: 'Consumo anual',
    tituloCurva: '¿Deseas actualizar la curva de consumo?',
  },
  InscritosDetalle: {
    nombre: 'Nombre',
    Dni: 'Dni',
    Email: 'Email',
    apellidos: 'Apellidos',
    telefon: 'Teléfono',
    Direccion: 'Dirección Completa',
    domicilio: 'Domicilio',
    poblacion: 'Poblacion',
    provincia: 'Provincia',
    razonSocial: 'Razón Social',
    codigoPostalFiscal: 'Código Postal Fiscal',
    domicilioFiscal: 'Domicilio Fiscal',
    nifEmpresa: 'NIF Empresa',
    codigoPostal: 'Codigo Postal',
    precioCompensacion: 'Precio Compensación',
    tipoConsumo: 'Tipo de consumo',
    tipoInscrito: 'Tipo de inscrito',
    tipoPropiedad: 'Tipo de propiedad',
    tipoTarifa: 'Tipo de tarifa',
    tarifa1: '2.0TD',
    tarifa2: '3.0TD',
    cups: 'Cups',
    deleteInscrito: 'Eliminar el inscrito',
    mensajeModalDelete: 'Está seguro que quiere eliminar el inscrito:',
  },
  ColorPicker: {
    typeHexColor: 'Introduce un color hexadecimal',
  },
  ComunidadesCustomOnboardingProcess: {
    updateSuccess: 'Datos actualizados correctamente.',
    errors: {
      errorGettingCustomOnboardingData: 'Error obteniendo la información personalizada',
      updating: 'Error actualizando la información personalizada',
    },
    form: {
      title: 'Personaliza el onboarding de la comunidad',
      section1: 'Activar/Desactivar personalización',
      section2: 'Ajustes de personalización de contenido',
      forzarRevision: 'Forzar revisión/validación de actualizar tarifas',
      faqTilte: 'Añadir Faqs',
      horarioTitle: 'Añadir Horarios',
      horarios: 'Horarios',
      tipodeContacto: 'Tipos de contacto',
      tipodeContactoTitle: 'Añadir Tipos de contacto ',
      tipodePopUpTitle: 'Agregar configuración del popup',
      ofertaTitle: 'Ofertas',
      ofertaPageTitle: 'Condiciones generales ofertes',
      confirmacionMensajeTab: 'Confirmación mensaje',
      otrosTab: 'Otros',
      informe: '¿Te gustaría habilitar la opción de recibir Informes Automáticos?',
      PopUp: 'Modal pasos onboarding',
      confirmacionMensaje: 'Confirmación mensaje finalización de onboarding',
      confirmacionMensajeExplicacion:
        'Escribe el título y contenido del mensaje de confirmación de finalización de onboarding',
      labels: {
        tratamientoDatosPersonales: ' Tratamiento datos personales',
        tituloPopUpInicial: 'Título del Pop-Up Inicial',
        descripcionPopUpInicial: 'Descripción del Pop-Up Inicial',
        paso1PopUpInicial: 'Paso 1 del Pop-Up Inicial',
        paso2PopUpInicial: 'Paso 2 del Pop-Up Inicial',
        paso3PopUpInicial: 'Paso 3 del Pop-Up Inicial',
        paso4PopUpInicial: 'Paso 4 del Pop-Up Inicial',
        tipodeContacto: 'Tipos de contacto',
        email: 'Email de contacto',
        telephone: 'Teléfono de contacto',
        privacyPolicy: 'Política de privacidad',
        descriptionTextStep2: 'Texto descripción paso 2',
        successButtonText: 'Texto para el botón de la página de exito',
        primaryColor: 'Color principal',
        secondaryColor: 'Color secundario',
        AppCustom: 'Personalización de la App',
        WebCustom: 'Personalización de la web',
        forzarpaso2: 'Saltar el paso 2 del onboarding.',
        comunicacionesComerciales: 'Mostrar check de solicitud de contacto comercial',
        tituloFinalizacionOnboarding: 'Título',
        subtituloOnboarding: 'Subtítulo / contenido',
        mensajeFinalizacionOnboarding: 'Contenido',
        forzarRevisionActualizarTarifa: 'Forzar revision para actualizar tarifa',
        peuDePagina: 'Pie de página',
        informes: 'Informes Automáticos',
        informeInmediato: 'Enviar informe del primer intento',
      },
      placeholder: {
        email: 'Email de contacto',
        telephone: 'Teléfono de contacto',
        privacyPolicy: 'Política de privacidad',
        descriptionTextStep2: 'Texto descripción paso 2',
        successButtonText: 'Texto para el botón de la página de exito',
        primaryColor: 'Color principal',
        secondaryColor: 'Color secundario',
        tituloFinalizacionOnboarding: '¡Solicitud enviada!',
        mensajeFinalizacionOnboarding: 'Muchas gracias por habernos facilitado tus datos energeticos ...',
      },
      logo: {
        title: 'Logo Navbar',
        text: 'añade logo para el Navbar',
        note: '(Debe ser de AxBpx*)',
        button: 'añade logo',
      },
      image01: {
        title: 'Imagen paso 1',
        text: 'añade imagen del paso 1',
        note: '(Debe ser de 950x527px *)',
        button: 'añade imagen',
      },
      image02: {
        title: 'Imagen paso 2',
        text: 'añade imagen del paso 2',
        note: '(Debe ser de 950x527px *)',
        button: 'añade imagen',
      },
      image03: {
        title: 'Imagen paso 3',
        text: 'añade imagen del paso 3',
        note: '(Debe ser de 950x527px *)',
        button: 'añade imagen',
      },
      image04: {
        title: 'Imagen paso 4',
        text: 'añade imagen del paso 4',
        note: '(Debe ser de 950x527px *)',
        button: 'añade imagen',
      },
      image05: {
        title: 'Imagen final',
        text: 'añade imagen final',
        note: '(Debe ser de 950x527px *)',
        button: 'añade imagen',
      },
    },
  },
  TooltipOferta: {
    title: 'Sube un archivo CSV del año',
    subtitle:
      'El archivo CSV debe contener el año completo con las fechas en formato: YYY/MM/DD y las horas: 1-24. Ten en cuenta que todas las columnas deben estar separadas con ‘;’. Por ejemplo: 2023/1/1;1;164',

    BetaMaxTitulo:
      'Este campo se utiliza para poner límite de cada miembro de la comunidad. El valor de este campo influye mucho a la hora de calcular los resultados. Aquí tienes algunos consejos:',
    BetaMaxLista1: 'Establece el valor en un rango recomendado entre 0.1 y 0.4.',

    BetaMaxLista2: 'La suma de BetaMax de todos miembros debe ser más o igual a 1.0',

    BetaMaxLista3: 'Asegúrate de que refleje la cantidad de miembros y sus tamaños.',

    BetaMinTitulo:
      'Este campo se utiliza para asegurar  que todos los clientes obtienen su parte de energía  solar en las horas de falta de generación. También sirve como un limito para filtrar las combinaciones con sobre de miembros:',
    BetaMinLista1: 'Establece el valor en un rango recomendado entre 0.0 y 0.1.',

    BetaMinLista2: 'La suma de BetaMin de todos miembros debe ser menos o igual a 1.0',

    BetaMinLista3: 'Asegúrate de que sea lo suficientemente bajo para evitar excedentes de energía.',

    AddBetaMaxTitulo:
      'Este campo se utiliza para clientes grandes que tienen una mayor participación en el consumo de energía de la comunidad,que tengan más BetaMax que la configuración Matcher. Aquí tienes algunos consejos:',
    AddBetaMaxLista1: 'Establece el valor en un rango recomendado entre 0.1 y 0.5.',

    AddBetaMaxLista2: 'Asegúrate de que refleje una proporción eficiente de intercambio de energía.',

    AddBetaMinTitulo:
      'Este campo se utiliza para clientes pequeños que tienen una menor participación en el consumo de energía de la comunidad, que tenga menos de la BetaMin de la configuración Matcher. Sigue estos consejos:',
    AddBetaMinLista1: 'Establece el valor en un rango recomendado entre 0.0 y 0.1.',

    AddBetaMinLista2: 'Asegúrate de que sea lo suficientemente bajo para evitar excedentes de energía.',

    TerminoEnergiaTitulo:
      'Este conjunto de campos contiene valores cruciales para los cálculos de energía. Aquí tienes algunos consejos:',
    TerminoEnergiaLista1: 'Mantén los valores actualizados y reflejando los precios actuales en euros.',
    TerminoEnergiaLista2:
      'Por ejemplo, si el término de energía P1 de un cliente es de 15 céntimos, introdúcelo como 0.15 en el sistema.',
    ExcedenteTitulo: 'Este campo se utiliza para calcular los excedentes. Sigue estas recomendaciones:',
    ExcedenteLista1: 'Mantén el valor actualizado y en euros.',
    ExcedenteLista2:
      'Por ejemplo, si el precio de compensación del cliente es de 7 céntimos, introdúcelo como 0.07 en el sistema.',
  },
  RolDetailPage: {
    RolData: {
      title: 'Administración de Roles y Permisos',
      Actualizar: 'Editar perfil',
      MensajeModal: 'Está seguro de que desea eliminar',
      ver: 'Ver',
      perfiles: 'Roles',
      error: 'Error',
      crear: 'Crear nuevo rol',
      labels: {
        name: 'Nombre',
      },
    },
  },
  RolPermisionDetailPage: {
    RolData: {
      titleRol: 'Administración de Roles',
      titlePermisos: 'Administración de Permisos',
      subtitle: 'Accesos',
      mensajeModalDelete: 'Está seguro que quiere eliminar',
      update: 'Actualizar nombre',
      labels: {
        name: 'Nombre',
        selected: 'Seleccionado',
      },
    },
  },
  UsuarioRolPage: {
    title: 'Roles asignados',
    noData: 'El usuario no tiene CUPS vinculados.',
    headers: {
      rol: 'Rol',
      estado: 'Estado',
    },
    viewInstallation: 'Ver instalación',
    noRole: 'No tiene ningún permiso asignado',
  },
  UsuarioCupsAssociarPage: {
    title: 'Cups autorizados',
    noData: 'El usuario no tiene CUPS vinculados.',
    subtitle: 'Autorizar CUPS',

    headers: {
      Cups: 'CUPS',
      estado: 'Estado',
      name: 'Nombre',
      domicilio: 'Domicilio',
    },
    viewInstallation: 'Ver instalación',
    noRole: 'No tiene ningún cups asignado',
    quitar: 'quitar',
  },

  SolicitudCupsTerceroPage: {
    title: 'Solicitud de Autorización para CUPS de Terceros',
    titleEnviada: 'Solicitudes enviadas',
    titleRecivida: 'Solicitudes recibidas',
    error: 'Se ha producido un error',
  },
};

export default translations;
